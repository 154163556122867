import { SnakeToCamelCase } from '../helpers/typeHelper'

export interface PermissionResult {
  enabled: boolean
  reason?: PermissionErrorType | null
}

export type Permissions = Record<PermissionType, PermissionResult>
export type PermissionsCamelCased = Record<SnakeToCamelCase<PermissionType>, PermissionResult>

export type PermissionsConfigType = Record<
  string,
  {
    permission: PermissionType
    allow?: string[]
    fallbackUrl: string
  }
>

export enum PermissionType {
  AI_CONSULTANT = 'ai_consultant',
  BOOK_LOADS = 'book_loads',
  BOOK_FACTORING_LOADS = 'book_factoring_loads',
  DRIVER_INTELLIGENCE_TAB = 'driver_intelligence_tab',
  ADD_TEAMMATE = 'add_teammate',
  ADD_TEAMMATE_CHARGE_SUMMARY = 'add_teammate_charge_summary',
  DEACTIVATE_TEAMMATE = 'deactivate_teammate',
  DEACTIVATE_TEAMMATE_CHARGE_SUMMARY = 'deactivate_teammate_charge_summary',
  SEARCH = 'search',
  JOB_LIST = 'job_list',
  CT_CASH_TAB = 'ct_cash_tab',
  CT_CASH_DEBIT_TAB = 'ct_cash_debit_tab',
  CT_CASH_CREDIT_TAB = 'ct_cash_credit_tab',
  CT_CASH_ISSUE_CARD = 'ct_cash_issue_card',
  CT_CASH_CREATE_CARDHOLDER = 'ct_cash_create_cardholder',
  CT_CASH_BONUS_PAYOUT = 'ct_cash_bonus_payout',
  VIEW_STRIPE_SUBSCRIPTION = 'view_stripe_subscription',
  HIDE_CHECKLIST = 'hide_checklist',
  EDIT_STRIPE_SUBSCRIPTION = 'edit_stripe_subscription',
  START_STRIPE_SUBSCRIPTION = 'start_stripe_subscription',
  OPS_SUPPORT = 'ops_support',
  USE_INVOICING = 'use_invoicing',
  CREATE_STARTER_UPSELL = 'create_starter_upsell',
  CREATE_CREDIT_UPSELL = 'create_credit_upsell',
  CREATE_FACTORING_UPSELL = 'create_factoring_upsell',
  ELD_SETUP = 'eld_setup',
  EQUIPMENT_SETUP = 'equipment_setup',
  BROKER_SETUP = 'broker_setup',
  PAYMENT_PROFILE = 'payment_profile',
  SCHEDULE_OPTIMIZER = 'schedule_optimizer',
  SIMPLIFIED_CREDIT_VIEW = 'simplified_credit_view',
  TRANSFER_BALANCE = 'transfer_balance',
  TRANSFER_TO_BANK = 'transfer_to_bank',
  REQUEST_CASH_ADVANCE = 'request_cash_advance',
  ADD_CARDHOLDER = 'add_cardholder',
  MANAGE_CARDHOLDER = 'manage_cardholder',
  VIEW_PAYMENT_PROGRESS_AND_ESCROW = 'view_payment_progress_and_escrow',
  VIEW_PAYMENT_PROGRESS = 'view_payment_progress',
  VIEW_ESCROW = 'view_escrow',
  VIEW_PERMIT_BOOK = 'view_permit_book',
  INSTANT_BID_BOOK_CONVOY_VC = 'instant_bid_book_convoy_vc',
  CT_INSURANCE = 'ct_insurance',
  DOWNLOAD_CREDIT_STATEMENT = 'download_credit_statement',
  SHOW_SHIPPER_FILTER = 'show_shipper_filter',
  MANAGE_PERMISSIONS = 'manage_permissions',
  TEAM_USER_CAN_BOOK_LOADS = 'team_user_can_book_loads',
  TEAM_USER_CAN_VIEW_RATES = 'team_user_can_view_rates',
  SAFETY_DASHBOARD = 'safety_dashboard',
  CANCEL_STRIPE_SUBSCRIPTION = 'cancel_stripe_subscription',
  EMAIL_CAN_BE_CHANGED = 'email_can_be_changed',
  LANE_TEMPLATES = 'lane_templates',
  CONTRACTOR_PAYMENTS = 'contractor_payments',
  CHECKR_PACKAGE_1_ONBOARDING = 'checkr_package_1_onboarding',
  CHECKR_PACKAGE_2_ONBOARDING = 'checkr_package_2_onboarding',
  CAN_VIEW_VGS = 'can_view_vgs',
  CAN_MODIFY_ONBOARDING_ITEMS = 'can_modify_onboarding_items',
}

export enum PermissionErrorType {
  ERROR = 'error',
  NOT_AVAILABLE = 'not_available',
  ADMIN_ROLE = 'admin_role',
  STRIPE_SUBSCRIPTION_NOT_STARTED = 'stripe_subscription_not_started',
  FLEX_STARTER_SEAT_QUANTITY = 'flex_starter_seat_quantity',
  TEAM_NOT_APPROVED = 'team_not_approved',
  MISSING_TEAM = 'missing_team',
}

export type TeamLevelPermissions = Record<
  PermissionType.TEAM_USER_CAN_BOOK_LOADS | PermissionType.TEAM_USER_CAN_VIEW_RATES,
  boolean
>
