export enum Environment {
  PRODUCTION = 'PRODUCTION',
  STAGING = 'STAGING',
  PREVIEW = 'PREVIEW',
  DEVELOPMENT = 'DEVELOPMENT',
  UNKNOWN = 'UNKNOWN',
}

export function detectEnvironment() {
  const originUrl = window.location.origin

  if (process.env.REACT_APP_CLOUDTRUCKS_ENV === 'production') {
    return Environment.PRODUCTION
  }

  if (process.env.REACT_APP_CLOUDTRUCKS_ENV === 'staging') {
    return Environment.STAGING
  }

  if (process.env.REACT_APP_CLOUDTRUCKS_ENV === 'preview') {
    return Environment.PREVIEW
  }

  if (process.env.REACT_APP_CLOUDTRUCKS_ENV === 'development') {
    return Environment.DEVELOPMENT
  }

  // Legacy check
  switch (originUrl) {
    case 'https://app.cloudtrucks.com':
    case 'https://console.cloudtrucks.com':
      return Environment.PRODUCTION
    case 'https://app.cloudtrucks-staging.com':
    case 'https://console.cloudtrucks-staging.com':
      return Environment.STAGING
    case 'https://localhost:3000':
    case 'https://localhost:3001':
    case 'http://localhost:3000':
    case 'http://localhost:3001':
    case 'http://localhost:8000':
    case 'http://webapp:8000':
      return Environment.DEVELOPMENT
    default:
      return Environment.UNKNOWN
  }
}

export function isProductionEnv() {
  return detectEnvironment() === Environment.PRODUCTION
}

export function isStagingEnv() {
  return detectEnvironment() === Environment.STAGING
}

export function isDevelopmentEnv() {
  return detectEnvironment() === Environment.DEVELOPMENT
}

export const isProduction = process.env.NODE_ENV === 'production'
export const isDevelopment = process.env.NODE_ENV === 'development'
export const isTest = process.env.NODE_ENV === 'test'
export const isIntegrationTest = process.env.REACT_APP_INTEGRATION === 'true'

const VGS_APP_PROD_DOMAIN = 'https://vgs.app.cloudtrucks.com'
const VGS_APP_STAGING_DOMAIN = 'https://vgs.app.cloudtrucks-staging.com'
const VGS_CONSOLE_PROD_DOMAIN = 'https://vgs.console.cloudtrucks.com'
const VGS_CONSOLE_STAGING_DOMAIN = 'https://vgs.console.cloudtrucks-staging.com'
const VGS_DEV_DOMAIN =
  process.env.REACT_APP_DISABLE_VGS_IN_DEV && !process.env.REACT_APP_STAGING_PROXY ? '' : '/__vgs'

export function isConsole() {
  return (
    window.location.origin.includes('console') ||
    window.location.origin.includes(':3001') ||
    window.location.origin.includes(':3003')
  )
}

export function getVGSDomain() {
  if (isConsole()) {
    return getConsoleVGSDomain()
  }

  return getAppVGSDomain()
}

function getAppVGSDomain() {
  if (detectEnvironment() === Environment.PRODUCTION) {
    return VGS_APP_PROD_DOMAIN
  }

  // When we are running dev proxy against staging api
  if (process.env.REACT_APP_STAGING_PROXY) {
    return VGS_DEV_DOMAIN
  }

  if (detectEnvironment() === Environment.STAGING) {
    return VGS_APP_STAGING_DOMAIN
  }

  if (detectEnvironment() === Environment.DEVELOPMENT) {
    return VGS_DEV_DOMAIN
  }

  return VGS_DEV_DOMAIN
}

function getConsoleVGSDomain() {
  const env = detectEnvironment()
  const isProxiedStaging = env === Environment.STAGING && window.location.origin === 'https://localhost:3003'

  switch (env) {
    case Environment.STAGING:
    case Environment.PREVIEW:
      return isProxiedStaging ? VGS_DEV_DOMAIN : VGS_CONSOLE_STAGING_DOMAIN

    case Environment.PRODUCTION:
      return VGS_CONSOLE_PROD_DOMAIN

    case Environment.DEVELOPMENT:
    default:
      return VGS_DEV_DOMAIN
  }
}

export const LOG_ROCKET_APP_ID_WEB = '7zp8gw/cloudtrucks-web'
export const LOG_ROCKET_APP_ID_IOS = '7zp8gw/cloudtrucks-ios'
export const LOG_ROCKET_APP_ID_ANDROID = '7zp8gw/cloudtrucks-android'
export const LOG_ROCKET_APP_ID_CONSOLE = '7zp8gw/cloudtrucks-console'
export const privateFieldNames = [
  'password_passthrough',
  'password_encrypted',
  'api_key_encrypted',
  'tax_id_encrypted',
  'tax_form_encrypted',
  'ssn_encrypted',
]

export function getAppUrl() {
  switch (detectEnvironment()) {
    case Environment.STAGING:
    case Environment.PREVIEW:
      return 'https://app.cloudtrucks-staging.com'
    case Environment.PRODUCTION:
      return 'https://app.cloudtrucks.com'
    case Environment.DEVELOPMENT:
    default:
      return 'http://localhost:3000'
  }
}
