import { Theme, useMediaQuery } from '@material-ui/core'
import { Text } from 'shared/components/Text/Text'
import { lightGray1 } from 'shared/theme/colors'

import { Task, TaskStatusEnum } from './TaskList'
import { StyledCardContent, StyledIcon, StyledMain, StyledStatus } from './TaskList.styles'
import { getIconName } from './TaskList.util'
import { TaskListCta } from './TaskListCta'

interface TaskListContentProps {
  task: Task
  isInsideAccordion?: boolean
  isReadOnly?: boolean
}

export function TaskListContent({ task, isInsideAccordion, isReadOnly }: TaskListContentProps) {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'))
  const isDisabled = task.status === TaskStatusEnum.PREREQUISITES_NOT_MET
  const hasCta = task.onCtaClick && task.ctaText

  // This add functionality on mobile to navigate to cta when yo tap on whole row
  function handleClick() {
    if (!isMobile) return // If its not mobile this doesnt do anything
    if (task.subTasks?.length) return // If there are subtasks, don't do anything as parent will expand container on click
    if (!hasCta || isDisabled || isReadOnly) return // No cta
    task.onCtaClick?.()
  }

  return (
    <StyledCardContent
      $isInsideAccordion={isInsideAccordion}
      $shouldHideCta={isDisabled || !hasCta || isReadOnly}
      onClick={handleClick}
    >
      <StyledIcon>
        <img src={getIconName(task)} width="24px" alt="" />
      </StyledIcon>
      <StyledMain>
        <Text variant="header3" fontColor={isDisabled || isReadOnly ? lightGray1 : undefined}>
          {task.titleRenderer || task.title}
        </Text>
        {task.subTitle && (
          <Text variant="paragraph3" fontColor={lightGray1} fontWeight={400}>
            {task.subTitle}
          </Text>
        )}
      </StyledMain>
      <StyledStatus>
        <Text
          variant="paragraph2"
          fontColor={lightGray1}
          fontWeight={400}
          styles="display: flex; align-items: center;"
        >
          {task.statusText}
          {task.statusAdditionalText}
        </Text>
      </StyledStatus>
      <TaskListCta task={task} isReadOnly={isReadOnly} />
    </StyledCardContent>
  )
}
