import { Fragment } from 'react'

import { OnboardingChecklistName } from '../../models/onboarding'
import { StyledCard, StyledSubTasks } from './TaskList.styles'
import { TaskListContent } from './TaskListContent'
import { TaskListSubTask } from './TaskListSubTask'

export enum TaskStatusEnum {
  NOT_STARTED = 'NOT_STARTED',
  PREREQUISITES_NOT_MET = 'PREREQUISITES_NOT_MET',
  COMPLETED = 'COMPLETED',
  ERROR = 'ERROR',
  IN_PROGRESS = 'IN_PROGRESS',
}

export interface Task {
  key?: string
  name: OnboardingChecklistName
  title: string
  titleRenderer?: React.ReactNode
  subTitle?: string
  status: TaskStatusEnum
  statusText: string
  statusAdditionalText: React.ReactNode | React.ReactNode[]
  subTasks?: ReadonlyArray<SubTask>
  isExpanded?: boolean
  ctaText?: React.ReactNode | string | null
  onCtaClick?: () => void
  ctaRenderer?: React.ReactNode
}

export type SubTask = Omit<Task, 'subTasks' | 'isExpanded'>

export interface TaskListProps {
  tasks: ReadonlyArray<Task>
  isReadOnly?: boolean
}

export function TaskList({ tasks, isReadOnly }: TaskListProps) {
  // Fragment needed for storybook
  return (
    <>
      {tasks.map((task) => {
        function maybeRenderAccordion() {
          const isDisabled = task.status === TaskStatusEnum.PREREQUISITES_NOT_MET
          if (task.subTasks?.length && !isDisabled) {
            return (
              <Fragment key={`${task.title}-${task.statusText}-${task.key}`}>
                <TaskListContent task={task} isInsideAccordion isReadOnly={isReadOnly} />
                <StyledSubTasks>
                  {task.subTasks.map((t) => (
                    <TaskListSubTask
                      key={`${t.title}-${t.statusText}-${t.key}`}
                      subTask={t}
                      isReadOnly={isReadOnly}
                    />
                  ))}
                </StyledSubTasks>
              </Fragment>
            )
          }

          return (
            <TaskListContent task={task} isReadOnly={isReadOnly} key={`${task.title}-${task.statusText}`} />
          )
        }

        return (
          <StyledCard className="tasklist-card" key={`${task.title}-${task.statusText}`}>
            {maybeRenderAccordion()}
          </StyledCard>
        )
      })}
    </>
  )
}
